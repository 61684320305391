import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { window } from "browser-monads";

// Components
import Seo from "../components/SEO";
import Layout from "../components/Layout";

// Styles
import * as styles from "../styles/pages/markdown.module.css";

export default function Template({ data, location }) {
  const { t } = useTranslation();
  const { markdownRemark } = data;
  const { frontmatter, html, headings } = markdownRemark;
  const [activeLink, setActiveLink] = React.useState(null);

  const sidebar = {};
  let currentHeading = null;
  headings.forEach((heading) => {
    if (heading.depth === 1) {
      sidebar[heading.value] = [];
      currentHeading = heading.value;
    } else if (heading.depth === 2) {
      sidebar[currentHeading].push(heading);
    }
  });
  // scroll spy on html elements with id
  React.useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 120;

      const sections = document.querySelectorAll("h2[id]");
      const links = document.querySelectorAll('a[href^="#"]');
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        const sectionId = section.getAttribute("id");
        if (
          scrollPosition >= sectionTop &&
          scrollPosition <= sectionTop + sectionHeight
        ) {
          links.forEach((link) => {
            link.classList.remove(styles.isActive);
            if (link.getAttribute("href") === `#${sectionId}`) {
              link.classList.add(styles.isActive);

              setActiveLink(sectionId);
              window.history.replaceState(null, null, `#${sectionId}`);
            }
          });
        }
      });
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (activeLink) {
      const allLinks = document.querySelectorAll('a[href^="#"]');

      const activeLinkElement = Array.from(allLinks).find((link) =>
        link.classList.contains(styles.isActive)
      );

      const sidebarWrapper = document.getElementById("sidebarWrapper");

      if (activeLinkElement) {
        setTimeout(() => {
          sidebarWrapper.scrollTo({
            top: activeLinkElement.offsetTop - 100,
          });
        }, 200);
      }
    }
  }, [activeLink]);

  // Make link active on click
  const handleClick = (e) => {
    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach((link) => {
      link.classList.remove(styles.isActive);
    });
    e.target.classList.add(styles.isActive);
  };

  return (
    <Layout hasNavbarOnlyWhite>
      <div className={`${styles.sectionContainer} sectionContainer`}>
        <aside className={`${styles.aside}`} id="sidebarWrapper">
          {Object.keys(sidebar).map((heading) => (
            <div className={`${styles.aside__section}`} key={heading}>
              <h2>{heading}</h2>
              <ul>
                {sidebar[heading].map((heading) => {
                  const id = `#${
                    // convert heading value into id
                    heading.value
                      .toLowerCase()
                      .replace(/ /g, "-")
                      .replace(/[^\w-]+/g, "")
                  }`;
                  return (
                    <li key={id}>
                      <a
                        onClick={handleClick}
                        href={id}
                        id={`${id?.replace("#", "")}Link`}
                      >
                        {heading.value}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
        </aside>
        <div id="contentCard" className={`${styles.content}`}>
          <p className={styles.lastUpdate}>
            {t("last_update")}: {frontmatter.date}
          </p>
          <div
            id="content_wrapper"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!, $language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      headings {
        depth
        value
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;

export const Head = ({
  pageContext,
  data: {
    markdownRemark: {
      frontmatter: { title },
    },
  },
}) => {
  const isArabic = pageContext.i18n.language === "ar";
  return <Seo isArabic={isArabic} title={title} />;
};
